<template>
    <div>
        <div class="bg"></div>
        <div class="md-content resumen-dia">
            <div style="width: 95%; margin: 0 auto; padding-bottom: 100px">
                <div class="box tarifa-prom md-elevation-3">
                    <div class="box-header"></div>
                    <div class="box-body">{{resumen.data.moneda}} {{resumen.data.tarifa_promedio}}</div>
                    <div class="box-lead">{{lang.tarifa_promedio}}</div>
                </div>
                <div class="cols-2">
                    <div class="box bg-blue md-elevation-2">
                        <div class="box-body">{{resumen.stats.today_checkins}}</div>
                        <div class="box-lead">{{lang.checkin_hoy}}</div>
                    </div>

                    <div class="box bg-purple md-elevation-2">
                        <div class="box-body">{{resumen.stats.noshows}}</div>
                        <div class="box-lead">{{lang.cantidad_no_show}}</div>
                    </div>
                </div>
                <div class="box box-div md-elevation-3">
                    <div class="box-l">
                        <PercentageCircle :animate="true" size="large" :percent="parseInt(resumen.stats.ocupacion)" active-color="blue" complete-color="green"/>
                    </div>
                    <div  class="box-r">
                        <div class="box-header">{{lang.ocupacion}}</div>
                        <div class="lead" style="margin-top: 10px; text-align: center">
                            {{resumen.stats.cuartos_ocupados}} {{lang.Habitaciones_ocupadas}}
                        </div>
                        <div class="box-lead">{{resumen.stats.hospedados_privadas}} {{lang.privadas}}
                            <template v-if="resumen.stats.has_compartidas">, {{resumen.stats.hospedados_compartidas}} {{lang.dormitorio_compartido}}</template></div>
                    </div>
                </div>
                <div class="box box-div md-elevation-3">
                    <div class="box-l">
                        <PercentageCircle :animate="true" size="large" :percent="parseInt(resumen.stats.capacidad_usada)" active-color="green" complete-color="orange"/>
                    </div>
                    <div class="box-r" >
                        <div class="box-header">{{lang.capacidad_usada}}</div>
                        <div class="box-lead">{{resumen.stats.hospedados}} {{lang.personas_hospedadas}}</div>
                    </div>
                </div>
              <template v-if="resumen.tables.categorias_combinadas">
                <!-- ventas e ingresos por categoria -->
                <md-card style="margin-bottom: 2rem">
                    <md-card-header>
                        <h1 class="md-title">{{lang.sales_income_category}}</h1>
                    </md-card-header>

                    <div class="table">
                        <div class="table-row">
                            <div class="table-row-title">
                                <template v-if="resumen.tables.numerocuartoscompartidos[0].ncuartos > 0">
                                    {{lang.alojamiento_privadas}}
                                </template>
                                <template v-else>
                                    {{lang.alojamiento}}
                                </template>
                            </div>

                            <div class="table-row-body">
                                <div class="table-row-col">
                                    <label> {{lang.ventas}} </label>
                                    <span> {{resumen.data.moneda}} {{resumen.tables.tarifa_suma_privadas}}</span>
                                </div>
                                <div class="table-row-col">
                                    <label> {{lang.ingreso}} </label>
                                    <span> {{resumen.data.moneda}} {{resumen.tables.ingresosprivada[0].total}}</span>
                                </div>
                                <div class="table-row-col">
                                    <label> {{lang.recaudo}} </label>
                                    <span> {{resumen.tables.recaudoprivadas}} %</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <template v-if="resumen.tables.numerocuartoscompartidos[0].ncuartos > 0">
                        <md-divider></md-divider>
                        <div class="table">
                            <div class="table-row">
                                <div class="table-row-title">
                                    {{lang.alojamiento_compartidas}}
                                </div>

                                <div class="table-row-body">
                                    <div class="table-row-col">
                                        <label> {{lang.ventas}} </label>
                                        <span> {{resumen.data.moneda}} {{resumen.tables.tarifa_suma_compartidas}}</span>
                                    </div>
                                    <div class="table-row-col">
                                        <label> {{lang.ingreso}} </label>
                                        <span> {{resumen.data.moneda}} {{resumen.tables.ingresoscompartida[0].total}}</span>
                                    </div>
                                    <div class="table-row-col">
                                        <label> {{lang.recaudo}} </label>
                                        <span> {{resumen.tables.recaudoprivadas}} %</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-for="cat in resumen.tables.categorias_combinadas">
                        <md-divider></md-divider>
                        <div class="table">
                            <div class="table-row">
                                <div class="table-row-title">
                                    {{cat.nombre}}
                                </div>

                                <div class="table-row-body">
                                    <div class="table-row-col">
                                        <label> {{lang.ventas}} </label>
                                        <span> {{resumen.data.moneda}} {{cat.total_venta}}</span>
                                    </div>
                                    <div class="table-row-col">
                                        <label> {{lang.ingreso}} </label>
                                        <span> {{resumen.data.moneda}} {{cat.suma}}</span>
                                    </div>
                                    <div class="table-row-col">
                                        <label> {{lang.recaudo}} </label>
                                        <span> {{cat.recaudo}} %</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <md-divider></md-divider>
                    <div class="table">
                        <div class="table-row">
                            <div class="table-row-title">
                                {{lang.extras}}
                            </div>

                            <div class="table-row-body">
                                <div class="table-row-col">
                                    <label> {{lang.ventas}} </label>
                                    <span> {{resumen.data.moneda}} {{(resumen.tables.total_ventas_extra)? resumen.tables.total_ventas_extra:'0.00'}}</span>
                                </div>
                                <div class="table-row-col">
                                    <label> {{lang.ingreso}} </label>
                                    <span> {{resumen.data.moneda}} {{(resumen.tables.extraIncome)? resumen.tables.extraIncome:'0.00'}}</span>
                                </div>
                                <div class="table-row-col">
                                    <label> {{lang.recaudo}} </label>
                                    <span> {{resumen.tables.recaudoextras}} %</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </md-card>
                <!-- Egresos por categoria -->
                <md-table md-card style="margin-bottom: 2rem">
                    <md-table-toolbar>
                        <h1 class="md-title"> {{lang.egresos_por_categoria}}</h1>
                    </md-table-toolbar>
                    <md-table-row>
                        <md-table-head>{{lang.categoria}}</md-table-head>
                        <md-table-head>{{lang.total_ingresos}}</md-table-head>
                    </md-table-row>

                    <md-table-row v-for=" gasto in resumen.tables.egresos_gastos ">
                        <md-table-cell>{{gasto.nombre}}</md-table-cell>
                        <md-table-cell>{{resumen.data.moneda}} {{gasto.total}}</md-table-cell>
                    </md-table-row>

                    <md-table-row v-for=" cat in resumen.tables.egresos_categoria ">
                        <md-table-cell>{{cat.nombre}}</md-table-cell>
                        <md-table-cell>{{resumen.data.moneda}} {{cat.total}}</md-table-cell>
                    </md-table-row>

                    <md-table-row>
                        <md-table-head>{{lang.total.toUpperCase()}}</md-table-head>
                        <md-table-head>{{resumen.data.moneda}} {{resumen.tables.total_egresos}}</md-table-head>
                    </md-table-row>
                </md-table>
                <!-- ingresos vs egresos -->
                <md-table md-card style="margin-bottom: 2rem">
                    <md-table-toolbar>
                        <div class="md-title"> {{lang.ingresos_vs_egresos}}</div>
                    </md-table-toolbar>
                    <md-table-row>
                        <md-table-head>{{lang.forma_pago}}</md-table-head>
                        <md-table-head>{{lang.ingresos}}</md-table-head>
                        <md-table-head>{{lang.egresos}}</md-table-head>
                    </md-table-row>

                    <md-table-row v-for=" (valor, forma) in resumen.tables.formas_de_pago ">
                        <md-table-cell>{{forma.nombre}}</md-table-cell>
                        <md-table-cell>{{resumen.data.moneda}} {{forma.ingreso}}</md-table-cell>
                        <md-table-cell>{{resumen.data.moneda}} {{forma.egreso}}</md-table-cell>
                    </md-table-row>
                    <md-table-row>
                        <md-table-head>{{lang.total.toUpperCase()}}</md-table-head>
                        <md-table-head>{{resumen.data.moneda}} {{resumen.tables.total_ingresos}}</md-table-head>
                        <md-table-head>{{resumen.data.moneda}} {{resumen.tables.total_egresos}}</md-table-head>
                    </md-table-row>
                </md-table>
              </template>
            </div>
        </div>
    </div>

</template>

<script>
    import {request, Utility} from "../../helpers";
    import PercentageCircle from 'vue-css-percentage-circle';

    export default {
        name: "Resumen",
        components:{PercentageCircle},
        data(){
            return {
                resumen : {
                    data:{}, stats: {}, tables: {}
                },
            }
        },
        computed:{
            lang(){return this.$store.state.lang},
        },
        methods :{
         async loadData(){
              this.$store.state.loading = true;
              let r = await  request(base_url+'/summaryday/jsonData?view=json&jwt='+this.$store.state.jwt);
              r = r.r.data;
              if(r.status == 'ok'){
                  this.resumen = r.data;
              }

             this.$store.state.loading = false;
          }
        },
        mounted() {
            this.$store.state.loading = false;
            this.$store.commit('setTitle', this.lang.resumen_dia);
            this.$nextTick(function(){
                this.loadData();
            })
        }
    }
</script>

<style lang="scss" scoped>
    .table{
        .table-row{
            .table-row-title{
                text-align: center;
                width: 100%;
                padding: 5px;
                padding: 0.7rem;
                font-weight: 300;
                font-size: 1.1rem;
            }
            .table-row-body{
               display: flex;
                justify-content: space-around;

                .table-row-col{
                    display: flex;
                    flex-direction: column;
                    padding: 5px;
                    text-align: center;
                    label{
                        font-size: 0.7rem;
                        padding: 0 0.5rem;
                        color: #797676;
                        //background: whitesmoke;
                        border-radius: 0.5rem;
                        font-weight: 500;
                    }
                    span{
                        padding: 0.5rem;
                        font-size: 1rem;
                    }
                }
            }
        }
    }
    .md-title{

        margin-top: 0 !important;
        font-size: 20px !important;
    }
    .bg {
      background-color: #4fbbbd;
        height: 100px;
    }
    .b-top{
        border-top: 1px solid grey;
    }
    .text-center{
        text-align: center;
    }
    .md-content {
        position: relative;
        height: calc(100vh);
        overflow: auto;
        width: 100%;
        margin: -100px auto 0px;
        padding-top: 40px;
        &.md-table{
            height: inherit;
        }
        .cols-2{
            display: flex;
            align-items: stretch;
            .box{
                margin: 0 0.5em 2em;
            }

        }
        .box{
            background-color:#fffffff2;
            border-radius: 10px;
            padding: 20px;
            width: 98%;
            margin: 0 auto 2em;

            &.box-div{
                display: flex;
                align-items: center;
            }

            .box-l{

            }
            .box-r{
                padding-left: 20px;
                flex-grow: 1;
                .box-header{
                    text-align: center;
                }

                .box-lead{
                    padding: 5px 0;
                }

            }
            .box-header{
                font-size: 1.2em;
                font-weight: bold;
            }
            .box-body{
                text-align: center;
                padding: 20px 0;

                font-size: 2em;
            }
            .box-lead{
                text-align: center;
                color : grey;
                font-size: 0.8rem;
            }
        }
        .sub-head {
            font-size: 14px;
            color: rgba(0, 0, 0, .6);
        }

        .md-select-value {
            padding-left: 13px;
        }


        .alert {
            text-align: center;
            margin-bottom: 10px;
            background-color: #ff5252;
            color: white;
            font-size: 14px;
            padding: 7px;
        }
    }
</style>
